<template>
  <div>
    <h4 class="text-center">Загрузка обновления</h4>
    <b-progress animated>
      <b-progress-bar :value="uploadProgress.loaded / uploadProgress.total" :max="1">
        <strong>
          {{ `${convertBytesToMegabytes(uploadProgress.loaded)} МБ / ${convertBytesToMegabytes(uploadProgress.total)} МБ` }}
        </strong>
      </b-progress-bar>
    </b-progress>
  </div>
</template>

<script>
export default {
  name: "upload-progress",
  props: {
    uploadProgress: {
      required: true,
      type: Object
    }
  },
  methods: {
    convertBytesToMegabytes(bytes) {
      return (bytes / 1048576.0).toFixed(2);
    }
  }
}
</script>