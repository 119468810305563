<template>
  <div v-if="updateData != null">
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group label="Старшая версия">
        <b-form-input v-model="$v.updateData.major.$model" required="required"
                      :type="'number'"
                      :state="$v.updateData.major.$dirty ? !$v.updateData.major.$error : null"
                      :readonly="!creating || !isAdmin">
        </b-form-input>
      </b-form-group>
      <b-form-group label="Младшая версия">
        <b-form-input v-model="$v.updateData.minor.$model" required="required"
                      :type="'number'"
                      :state="$v.updateData.minor.$dirty ? !$v.updateData.minor.$error : null"
                      :readonly="!creating || !isAdmin">
        </b-form-input>
      </b-form-group>
      <b-form-group label="Версия патча">
        <b-form-input v-model="$v.updateData.patch.$model" required="required"
                      :type="'number'"
                      :state="$v.updateData.patch.$dirty ? !$v.updateData.patch.$error : null"
                      :readonly="!creating || !isAdmin">
        </b-form-input>
      </b-form-group>
      <b-form-group label="Тип обновления">
        <b-form-select :text="creating ? 'Выберите тип обновления' : updateData.type"
                       v-model="$v.updateData.type.$model"
                       :state="$v.updateData.type.$dirty ? !$v.updateData.type.$error : null"
                       :disabled="!creating || !isAdmin" block variant="primary" required="required">
          <option value="MAJOR">MAJOR</option>
          <option value="MINOR">MINOR</option>
          <option value="PATCH">PATCH</option>
        </b-form-select>
      </b-form-group>
      <b-form-group label="Наименование обновления">
        <b-form-input v-model="$v.updateData.title.$model"
                      :state="$v.updateData.title.$dirty ? !$v.updateData.title.$error : null"
                      :readonly="!creating || !isAdmin">
        </b-form-input>
      </b-form-group>
      <b-form-group label="Дата и время создания обновления" v-if="!creating">
        <b-form-input v-model="new Date($v.updateData.releaseDate.$model).toLocaleString()"
                      :state="$v.updateData.releaseDate.$dirty ? !$v.updateData.releaseDate.$error : null"
                      :readonly="true">
        </b-form-input>
      </b-form-group>
      <b-form-group label="Перечень изменений">
        <b-form-textarea v-model="$v.updateData.notes.$model"
                         :state="$v.updateData.notes.$dirty ? !$v.updateData.notes.$error : null"
                         :readonly="!creating || !isAdmin"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group label="Файл обновления" v-if="creating">
        <b-form-file accept="application/vnd.android.package-archive" class="my-2" @input="loadFile" required="required"
                     v-model="file"></b-form-file>
      </b-form-group>
      <b-form-group>
        <b-button variant="primary" class="w-100" v-on:click="downloadFile" v-if="!creating">
          Скачать файл обновления
        </b-button>
      </b-form-group>
      <b-form-group v-if="creating">
        <b-form-checkbox v-model="$v.updateData.sendPush.$model" :unchecked-value="true" :value="true"
                         :state="$v.updateData.sendPush.$dirty ? !$v.updateData.sendPush.$error : null">
          Отправить пуш-уведомление о появлении нового обновления
        </b-form-checkbox>
      </b-form-group>
      <b-form-group></b-form-group>
      <b-button variant="primary" class="w-100" type="submit" :disabled="submitting || $v.updateData.$anyError"
                v-if="creating">
        Создать
      </b-button>
      <b-button class="w-100" v-bind:to="'/'" v-if="!creating">
        Назад
      </b-button>
      <b-alert class="mt-2" variant="info" :show="submitting">
        Создание...
      </b-alert>
    </b-form>
    <b-modal v-model="submitting" hide-footer hide-header no-close-on-backdrop centered>
      <upload-progress :upload-progress="uploadProgress"/>
    </b-modal>
  </div>
  <div v-else>

  </div>
</template>
<style>
.img-wrapper img {
  max-width: 100%;
  height: auto;
}
</style>
<script>
import {required} from "vuelidate/lib/validators";
import update from '../../modules/update';
import {BIconDownload, BIconTrash} from 'bootstrap-vue';
import {mapGetters} from "vuex";
import UploadProgress from "@/components/UploadProgress";

export default {
  components: {
    'upload-progress': UploadProgress,
    'b-icon-download': BIconDownload,
    'b-icon-trash': BIconTrash
  },
  mounted() {
    if (this.$route.params.major != null && this.$route.params.minor != null && this.$route.params.patch != null) {
      this.reloadUpdateData(this.$route.params.major, this.$route.params.minor, this.$route.params.patch);
    } else {
      this.creating = true;
      this.updateData = {
        major: null,
        minor: null,
        patch: null,
        title: null,
        releaseDate: null,
        notes: null,
        type: null,
        sendPush: false,
      };
    }
  },
  data() {
    return {
      submitting: false,
      file: null,
      updateData: null,
      uploadProgress: {
        loaded: 0,
        total: 0
      }
    };
  },
  props: {
    creating: {
      type: Boolean,
      default: false
    },
  },
  validations() {
    let updateDataValidation = {
      major: {
        required
      },
      minor: {
        required
      },
      patch: {
        required
      },
      type: {
        required
      },
      sendPush: {},
      releaseDate: {},
      title: {},
      notes: {},
    };
    return {
      updateData: updateDataValidation
    };
  },
  computed: {
    ...mapGetters([
      "isAdmin"
    ])
  },
  methods: {
    onSubmit() {
      this.$v.updateData.$touch();
      if (this.submitting || this.$v.updateData.$anyError) {
        return;
      }
      this.submitting = true;
      update.createUpdate(this.updateData, this.file, this.uploadProgress)
          .then(() => {
            console.log('Обновление создано');
          })
          .then(() => {
            this.reloadUpdateData(this.updateData.major, this.updateData.minor, this.updateData.patch);
            this.$router.push(`/${this.updateData.major}.${this.updateData.minor}.${this.updateData.patch}`);
            this.$bvToast.toast('Обновление успешно создано', {
              variant: 'success',
              solid: true,
              noCloseButton: true
            });
          })
          .catch((e) => {
            if (e.isAxiosError) {
              if (e.response.status === 413) {
                this.$bvToast.toast('Превышен допустимый размер файла при загрузке', {
                  title: 'Ошибка',
                  autoHideDelay: 5000,
                  appendToast: true
                });
              } else {
                this.$bvToast.toast('Возникла неизвестная ошибка при загрузке', {
                  title: 'Ошибка',
                  autoHideDelay: 5000,
                  appendToast: true
                });
              }
            }
            throw e;
          })
          .finally(() => {
            this.submitting = false;
          });
    },
    loadFile(att) {
      const fr = new FileReader();
      fr.onload = function () {
        att.urlValue = fr.result;
      }
    },
    downloadFile() {
      this.$bvToast.toast('Файл обновления скачивается, подождите...', {
        title: 'Загрузка',
        autoHideDelay: 3000,
        appendToast: true
      });
      return update.downloadFile(this.updateData.major, this.updateData.minor, this.updateData.patch)
          .then(resp => {
            const blob = new Blob([resp.data], {type: 'application/vnd.android.package-archive'});
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);

            let filename = 'update-' + this.updateData.major + '.' + this.updateData.minor + '.' + this.updateData.patch + '.apk';
            const contentDisposition = resp.headers['content-disposition']
            if (contentDisposition != null) {
              const split = contentDisposition.split('filename=');
              if (split.length > 1) {
                filename = split[1];
              }
            }
            link.download = filename;
            link.click();
            URL.revokeObjectURL(link.href);
          });
    },
    reloadUpdateData(major, minor, patch) {
      return update.getSingle(major, minor, patch)
          .then(resp => {
            this.updateData = resp.data;
            return Promise.resolve(this.updateData);
          });
    }
  }

}
</script>
