import {AXIOS} from './backend-config';

export default {
    getUpdatesList(pageSize, pageNumber) {
        return AXIOS.get('/update/list', {
            params: {
                pageSize,
                pageNumber
            }
        })
    },
    getSingle(major, minor, patch) {
        return AXIOS.get(`/update/${major}.${minor}.${patch}`);
    },
    createUpdate(updateInfo, file, uploadProgress) {
        const formData = new FormData();
        for (const [key, value] of Object.entries(updateInfo)) {
            if (value != null) {
                formData.append(key, value);
            }
        }
        formData.append('file', file);
        return AXIOS.post('/update/upload', formData, {
            headers: {
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            },
            maxContentLength: 524288000,
            maxBodyLength: 524288000,
            onUploadProgress: function(progressEvent) {
                uploadProgress.loaded = progressEvent.loaded;
                uploadProgress.total = progressEvent.total;
            }
        });
    },
    deleteUpdate(major, minor, patch) {
        return AXIOS.delete(`/update/${major}.${minor}.${patch}`);
    },
    downloadFile(major, minor, patch) {
        return AXIOS.get(`/update/${major}.${minor}.${patch}/file`, {responseType: 'blob'});
    }
}
